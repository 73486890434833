import React, { useEffect, useContext } from "react";
import { withPreview } from "gatsby-source-prismic";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";

// Components
import { DetermineModuleToDisplay } from "../components/utils/determine-module-to-display";
import { ContentContainer } from "../components/containers/container";
import { FlexContainer } from "../components/containers/flex-container";
import { PageTitle } from "../components/text/page-title";
import { ShareButtons } from "../components/buttons/share-buttons";
import { Spacer } from "../components/utils/spacer";

// Colors
import { tertiary } from "../components/utils/colors";

// Context
import { FooterColor } from "../components/context/footer-color";

const Page = styled.div`
	width: 100%;
`;

const ShareContainer = styled.div`
	position: relative;
	margin: 0 auto;

	max-width: 780px;
`;

const BuyingGuideContent = ({ data, pageContext }) => {
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	const content = data.prismicBuyingGuide.data.body.map(
		(content, outerIndex) => (
			<ContentContainer key={`collection_module_wrapper_${outerIndex}`}>
				<DetermineModuleToDisplay
					content={content}
					pageType={`buying-guide`}
					article={data.prismicBuyingGuide}
					contentIndex={outerIndex}
				/>
			</ContentContainer>
		)
	);

	return (
		<>
			<Page>
				<Helmet
					title={`${data.prismicBuyingGuide.data.seo_title}`}
					meta={[
						{
							name: "og:title",
							content: `${data.prismicBuyingGuide.data.seo_title}`,
						},
						{
							name: "description",
							content: `${data.prismicBuyingGuide.data.seo_description}`,
						},
						{
							name: "og:description",
							content: `${data.prismicBuyingGuide.data.seo_description}`,
						},
						{
							name: "twitter:title",
							content: `${data.prismicBuyingGuide.data.seo_title}`,
						},
						{
							name: "twitter:description",
							content: `${data.prismicBuyingGuide.data.seo_description}`,
						},
					]}
				/>
				<PageTitle title={data.prismicBuyingGuide.data.title.html} />

				{content}

				<FlexContainer
					largeDesktopPadding={`40px 135px 0 135px`}
					desktopPadding={`40px 60px 0 60px`}
					tabletPadding={`40px 35px 0 35px`}
					mobilePadding={`40px 15px 0 15px`}
					cv={false}
				>
					<Row justifyContent={"center"}>
						<Col col={12} md={9} lg={6} xl={7}>
							<ShareContainer>
								<ShareButtons
									title={data.prismicBuyingGuide.data.seo_title}
									text={data.prismicBuyingGuide.data.seo_description}
									location={`https://christian-watson.com${data.prismicBuyingGuide.url}`}
									image={data.prismicBuyingGuide.data.thumbnail.fluid.src}
									pageType={`journal`}
								/>
							</ShareContainer>
						</Col>
					</Row>
				</FlexContainer>
			</Page>
			<Spacer />
		</>
	);
};

const BuyingGuide = ({ data, pageContext }) => (
	<BuyingGuideContent data={data} pageContext={pageContext} />
);

export default withPreview(BuyingGuide);

export const query = graphql`
	query BuyingGuide($uid: String!) {
		prismicBuyingGuide(uid: { eq: $uid }) {
			prismicId
			url
			data {
				title {
					html
					text
				}
				seo_title
				seo_description
				thumbnail {
					fluid {
						src
					}
				}
				body {
					... on PrismicBuyingGuideBodyImage {
						id
						slice_type
						primary {
							image {
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
								dimensions {
									height
									width
								}
							}
							image_position
							image_size
							link {
								url
								type
							}
						}
					}
					... on PrismicBuyingGuideBodyVideo {
						id
						slice_type
						primary {
							video {
								width
								height
								html
								embed_url
							}
							link {
								url
								type
							}
							video_position
							video_size
						}
					}
					... on PrismicBuyingGuideBodyImageBanner {
						id
						slice_type
						primary {
							image {
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
								dimensions {
									height
									width
								}
							}
							link {
								url
								type
							}
						}
					}
					... on PrismicBuyingGuideBodyVideoBanner {
						id
						slice_type
						primary {
							link {
								url
								type
							}
							video {
								width
								height
								html
								embed_url
								title
							}
						}
					}
					... on PrismicBuyingGuideBodyText {
						id
						slice_type
						primary {
							text {
								html
							}
							text_columns
							text_position
						}
					}
					... on PrismicBuyingGuideBodyCollections {
						id
						slice_type
						items {
							collection {
								document {
									... on PrismicBuyingGuide {
										id
										url
										prismicId
										data {
											thumbnail {
												fluid {
													srcSetWebp
													srcWebp
													aspectRatio
												}
												alt
											}
											title {
												text
											}
										}
									}
								}
							}
						}
						primary {
							collections_title {
								html
							}
						}
					}
					... on PrismicBuyingGuideBodyMailchimp {
						id
						slice_type
						primary {
							mailchimp_form {
								document {
									... on PrismicMailchimp {
										id
										prismicId
										data {
											call_to_action_text
											privacy_policy {
												html
											}
											text {
												html
											}
										}
									}
								}
							}
						}
					}
					... on PrismicBuyingGuideBodyLimitedEdition {
						id
						slice_type
						primary {
							limited_edition_title {
								html
							}
						}
						items {
							product {
								url
								document {
									... on PrismicProduct {
										id
										data {
											linked_product
										}
									}
								}
							}

							image {
								alt
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								dimensions {
									width
									height
								}
							}
						}
					}
					... on PrismicBuyingGuideBodyEnquiryForm {
						id
						slice_type
						primary {
							enquiry_form {
								document {
									... on PrismicEnquiryForm {
										id
										data {
											form_title {
												html
											}
											form_text {
												html
											}
											form_message_placeholder
											form_disclaimer_text {
												html
											}
											dropdown_options {
												dropdown_option
												linked_email_address
											}
											additional_form_fields {
												additional_form_field
											}
											enable_file_upload
											file_upload_text {
												html
											}
										}
									}
								}
							}
						}
					}
					... on PrismicBuyingGuideBodyImageWithText {
						id
						slice_type
						primary {
							image_with_text {
								html
							}
							image_with_text_image {
								dimensions {
									width
									height
								}
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
							image_with_text_title {
								html
							}
							text_position
							text_vertical_position
						}
					}
					... on PrismicBuyingGuideBodyFaq {
						id
						slice_type
						primary {
							faq_section_title {
								html
							}
						}
						items {
							faq_title {
								text
							}
							faq_text {
								html
							}
						}
					}
					... on PrismicBuyingGuideBodySlideshow {
						id
						slice_type
						primary {
							slideshow_title {
								html
							}
						}
						items {
							image {
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
							text {
								html
							}
						}
					}
					... on PrismicBuyingGuideBodySlideshowWithText {
						id
						slice_type
						primary {
							slideshow_with_text_title {
								html
							}
							slideshow_with_text {
								html
							}
						}
						items {
							image_title {
								html
								text
							}
							image_text {
								html
								text
							}
							image {
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
						}
					}
				}
			}
		}
	}
`;
